
.wb-textarea-wrapper {
  background: transparent !important;
  border: grey 0.5px solid;
  border-radius: 50px !important;
}
.wb-send-btn {
  background-color: transparent !important;
  border-radius: 50px !important;
  border: grey 0.5px solid !important;
}
.wb-uploader {
  visibility: hidden !important;
}
.wb-history-background {
  background: transparent !important;
}
.wb-history-gradient {
  visibility: hidden;
}
.wb-chat-container {
  background: transparent !important;
}
.wb-history-background {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.wb {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-family: "Crabath",-apple-system,"Segoe UI",Rubik,Helvetica,Arial,sans-serif !important;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
  font-size: 17px !important;
}
.wb-text-message {
  background-color: transparent !important;
}
.wb-composer {
  background-color: transparent !important;
}
.wb-typing {
  background-color: transparent !important;
}
.wb-quick-reply {
  background-color: transparent !important;
  border: grey 0.5px solid !important;
  color: grey !important;
  font-family: "Crabath",-apple-system,"Segoe UI",Rubik,Helvetica,Arial,sans-serif !important;
  font-size: 17px !important;
}
.wb-quick-reply:hover {
  border: rgb(176, 176, 176) 0.5px solid !important;
}
.wb-animator {
  visibility: hidden !important;
}
.wb .wb-history-list::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182, 0.2) !important;
  width: 3px;
  border-radius: 5px !important;
  border: none !important;
}

.wb-header {
  height: 0px !important;
}

.wb-main-menu {
  visibility: hidden !important;
}