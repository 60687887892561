@font-face {
    font-family: "Jan Regular";
    src: url("/public/fonts/Jan-Regular.ttf") format('truetype');
}

@font-face {
    font-family: "Jan Bold";
    src: url("/public/fonts/Jan-Bold.ttf") format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: "Jan Italic";
    src: url("/public/fonts/Jan-Italic.ttf") format('truetype');
    font-style: italic;
}
  
@font-face {
    font-family: "Jan Bold Italic";
    src: url("/public/fonts/Jan-BoldItalic.ttf") format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Crabath";
    src: url("/public/fonts/CrabathText-Light2.otf") format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: "Crabath Italic";
    src: url("/public/fonts/CrabathText-LightItalic2.otf") format('opentype');
    font-weight: 400;
    font-style: italic;
}



h1, h2, h3, h4, h5, h6 {    
    font-family: "Jan Bold", Arial, sans-serif;
}

p {
    font-family: 'Crabath', 'Times New Roman', Times, serif;
}

button {
    font-family: "Jan Bold", Arial, sans-serif !important;
}
  
.st0-logo {
    fill: none !important;
    stroke: #000 !important;
    shape-rendering: "crispEdges";
}

.st1-logo {
    fill: #000 !important;
    shape-rendering: "crispEdges";
}

.dark-mode .st1-logo {
    fill: white !important;
}  

.dark-mode .st0-logo {
    stroke: white !important;
}

.dark-mode .st0 {
    stroke: white !important;
}

a {
    text-decoration: none;
}

.mobile-nav a {
    text-align: center;
}


/* destyling */
.iPUlgW {
    background-color: transparent !important;
}

.fpkYIL {
    box-shadow: none !important;
}

